@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
	margin: 0;
	font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* background-color: rgb(255, 127, 80); */
	background-color: rgb(101, 93, 91);
	/* background-color: rgb(252, 139, 97); */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
