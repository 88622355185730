* {
	margin: 0 auto;
	box-sizing: border-box;
}

:root {
	--mainColor: rgb(0, 72, 255);
}

.app {
	background-color: black;
}

/* NAVBAR  */

.nav-bar {
	list-style: none;
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 1rem;
	position: fixed;
	top: 0;
	z-index: 100000;
}

.nav-bar-item {
	cursor: pointer;
	background: linear-gradient(
		to bottom,
		var(--mainColor) 0%,
		var(--mainColor) 100%
	);
	font-size: 0.9rem;
	background-position: 0 100%;
	background-repeat: repeat-x;
	background-size: 2px 2px;
	color: #000;
	text-decoration: none;
}

.nav-bar-item:hover {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='rgb(0, 72, 255)' stroke-width='1' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
	background-position: 0 100%;
	background-size: auto 6px;
	background-repeat: repeat-x;
	text-decoration: none;
}
.nav-bar-item:focus {
	font-weight: 700;
}

.karma-link {
	color: var(--mainColor);

	cursor: pointer;
}

.arrow-fixed {
	position: fixed;
	width: 100vw;
	height: 10vh;
}

.arrow-up {
	position: fixed;
	margin-left: 2rem;
	bottom: 0;
	width: 1rem;
	border: solid var(--mainColor);
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 1rem;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	cursor: pointer;
}

.arrow-up:hover {
	border-width: 0 3.5px 3.5px 0;
	cursor: pointer;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	justify-content: center;
	text-align: center;
}

/* HERO */

.hero {
	width: 100vw;
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hero-text {
	position: absolute;
}

.intro-text {
	padding: 5rem;
	margin-top: 2rem;
	font-size: 5rem;
	line-height: 5rem;
	text-align: center;
	font-weight: 400;
}

article {
	margin-left: 1rem;
}

.portrait {
	position: absolute;
	width: 300px;
	top: 0;
	left: 100px;
	filter: sepia(50%);
	animation: 1s ease-out 0s 1 fadeIn;
}

.text-hiding-portrait {
	cursor: pointer;
}
.text-hiding-portrait:hover {
	color: var(--mainColor);
}

/* BUTTONS */

.ctas {
	width: 20%;
	display: flex;
	justify-content: space-around;
}
.cta-buttons {
	padding: 1rem;
	margin: 1rem;
	border: 3px solid black;
	color: black;
	text-decoration: none;
	font-weight: 700;
}

.cta-buttons:hover,
.cta-buttons-project:hover {
	background-color: var(--mainColor);
	transition-duration: 1s;
}

.cta-buttons-project {
	padding: 0.8rem;
	margin-right: 1rem;
	margin-bottom: 2rem;
	border: 3px solid black;
	color: black;
	text-decoration: none;
	font-weight: 700;
	text-align: center;
}

.smiley {
	position: absolute;
	margin-left: 60vw;
	margin-top: 40vh;
	width: 25vw;
}

#rotate {
	animation: rotation 5s infinite linear;
}

@keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

/* SECTIONS */

.section-titles {
	padding-top: 5rem;
	text-align: center;
	font-size: 1.6rem;
	text-transform: uppercase;
	/* color: var(--mainColor); */
}

/* PROJECTS */

.projects {
	padding-top: 4rem;
}

.project-container {
	border: 4px black solid;
	margin: 4rem;
	background-color: white;
	padding: 2rem;
	display: flex;
	box-shadow: 12px 12px 2px 1px rgba(10, 10, 20, 0.2);
}

.project-container:hover {
	transform: rotate(-0.2deg);
}

.image-container {
	width: 70%;
}

.project-image {
	width: 100%;
	margin-right: 3rem;
}

.project-info {
	margin-left: 2rem;
	width: 30%;
	position: relative;
	line-height: 1.3rem;
}

.project-info > h3 {
	margin-bottom: 1rem;
}

/* ABOUT */

.about {
	/* margin-top: 10rem; */
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tech {
	font-weight: 700;
	margin-top: 2rem;
	margin-bottom: 3rem;
}

.about-text {
	text-align: center;
	padding: 4rem;
	font-size: 2rem;
}

.tech-text {
	width: 60vw;
	text-align: center;
	line-height: 1.5rem;
}

/* CONTACT */

.contact-text {
	font-size: 4rem;
	text-align: center;
	margin-top: 3rem;
	font-weight: 400;
}

.contact {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 80%;
	height: 100vh;
}

.cta-link {
	text-decoration: none;
	color: rgb(0, 0, 0);
}
.cta-link:hover {
	text-decoration: underline;
}

.rainbow {
	position: absolute;
	z-index: -100;
	margin-left: 40vw;
	margin-top: 30vh;
	width: 40vw;
}

#wiggle {
	animation: wiggle 1s infinite linear;
}

@keyframes wiggle {
	from {
		-webkit-transform: rotate(-20deg);
	}
	to {
		-webkit-transform: rotate(-19.5deg);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* FOOTER */

.footer {
	display: flex;
	font-size: 0.8rem;
	margin: 2rem;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1000px) {
	.intro-text {
		padding: 2rem;
		margin-top: 3rem;
		font-size: 4rem;
		line-height: 4rem;
	}

	.project-container {
		margin: 2rem;
		padding: 2rem;
		display: block;
	}

	.project-info {
		margin-left: 0rem;
		width: 100%;
		position: relative;
		padding-bottom: 1rem;
	}

	.image-container {
		width: 100%;
	}
	.project-image {
		margin-right: 2rem;
		margin-bottom: 2rem;
	}
}

@media screen and (max-width: 600px) {
	.intro-text {
		padding: 2rem;
		margin-top: 5rem;
		font-size: 3rem;
		line-height: 3rem;
	}

	.project-info {
		padding-bottom: 0rem;
	}

	.project-image {
		margin-right: 1rem;
		margin-bottom: 1rem;
	}

	.project-container {
		margin: 2rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
		padding: 1rem;
		padding-bottom: 2rem;
		display: block;
	}

	.tech {
		margin-bottom: 2rem;
	}

	.about-texts {
		display: flex;
	}

	.about-text {
		width: 90vw;
		text-align: center;
		font-size: 1.5rem;
	}

	.tech-text {
		text-align: center;
	}

	.contact-text {
		font-size: 2rem;
	}

	.contact {
		margin: 2rem;
	}
}

@media screen and (max-width: 375px) {
	.smiley {
		margin: 0;
		margin-left: 40%;
		margin-top: 0;
		padding: 0;
		width: 20vw;
	}
	.about-text {
		font-size: 1rem;
		padding: 2rem;
	}
	.intro-text {
		padding: 1.5rem;
		margin-top: 2.3rem;
		font-size: 2.5rem;
		line-height: 2.5rem;
	}
}
